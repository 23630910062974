import React from 'react'
import { useNavigate } from 'react-router-dom';
import './BuyNowSection.css'
import { Col, Row } from 'react-bootstrap';

const BuyNowSection = () => {
    const navigate = useNavigate();

    const navigateToBuyNow = () => {
        navigate('/sleepzzz-pack-of-1')
    }
    return (
        <section className="buy-now-section">
            <div className="container fluid">
                <Row style={{ position: 'relative' }}>
                    <Col className='col-md-4'>
                        <h3 className='product-description-section'>Pop one gummie <br /> Sleep better & faster</h3>
                        <p className='product-description-section-p white-text'>Each sleep Sleepzz gummies is derived from award winning natural ingredients affron, herbs and modern science to deliver deep, Relaxed sleep.</p>
                    </Col>
                    <Col>
                        <img src="assets/bottle-front-image-main.webp" alt="sleepzzz-bottle-image" className='home-bottle-img' loading='lazy' width="380px" />
                    </Col>
                    <Col className='d-none d-md-block d-lg-block money-gurantee-box'>
                        <div className="row mt-4 mt-xl-5">
                            <div className="col-12 mt-3">
                                <img src="assets/money-back-garuntee.webp" className="img-fluid" alt="Money-back-guarantee" loading="lazy" />
                            </div>
                            <div className='d-flex align-items-center mt-2'>
                                <div className="bottom-price col-6" style={{ position: 'relative' }}>
                                    <h2 className="price ml-xl-2">₹ <span style={{ position: 'relative', color: '#8c8c8d' }}>1290</span> <span className="offer-price">990</span></h2>
                                </div>
                                <div className="col-6 p-0">
                                    <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
                <div className="row py-1 pl-2 mt-1 mx-1 py-xl-2 border-bottom border-top text-left d-md-flex product-features-section">
                    <div className="col p-1">
                        <h5>Deep Sleep <br />Faster</h5>
                    </div>
                    <div className="col p-1">
                        <h5>Reduces <br />Stress</h5>
                    </div>
                    <div className="col p-1">
                        <h5>Wake up <br /> Refreshed</h5>
                    </div>
                    <div className="col p-1">
                        <h5>30 <br /> Gummies</h5>
                    </div>
                </div>

                <div className="col-12 mt-4 mt-lg-5 d-none d-md-block">
                    <div className="card" id="awards">
                        <div className="card-body">
                            <div className="row row-cols-5">
                                <div className="col-12 text-center mb-3">
                                    <h2 className='award-heading'>Awards</h2>
                                </div>
                                <div className="col text-center">
                                    <img src="assets/finalist-2020.webp" className="award-images" alt="finalist-2020-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center">
                                    <img src="assets/finalist-2021.webp" className="award-images" alt="finalist-2021-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center">
                                    <img src="assets/finalist-2022.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center">
                                    <img src="assets/finalist-asia.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center mt-lg-0">
                                    <img src="assets/winner-2022.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center mt-4">
                                    <img src="assets/winner-asia-2019.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center mt-4">
                                    <img src="assets/winner-usa-2020.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center mt-4">
                                    <img src="assets/nie-awards-winner-2020.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center mt-4">
                                    <img src="assets/award.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                                <div className="col text-center mt-4">
                                    <img src="assets/gdtml-award.webp" className="award-images" alt="award-image" loading="lazy" width="100px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-3 d-md-none">
                    <div className="row text-center">
                        <div className="col-12 p-0">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mx-1">
                                        <div className="col-12">
                                            <h2>Awards</h2>
                                        </div>
                                        <div className="col-4 text-center">
                                            <img src="/assets/finalist-2020.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center">
                                            <img src="/assets/finalist-2021.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center">
                                            <img src="/assets/finalist-2022.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center mt-3">
                                            <img src="/assets/finalist-asia.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center mt-3">
                                            <img src="/assets/winner-2022.webp" className="award-images" alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center mt-3">
                                            <img src="/assets/winner-usa-2020.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center mt-1">
                                            <img src="/assets/nie-awards-winner-2020.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center mt-1">
                                            <img src="/assets/winner-asia-2019.webp" className="award-images"
                                                alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                        <div className="col-4 text-center mt-1">
                                            <img src="/assets/award.webp" className="award-images" alt="award-image" loading="lazy" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mt-xl-5 d-md-none d-lg-none money-gurantee-box">
                    <MoneyBackGuaranteeBox navigateToBuyNow={navigateToBuyNow} />
                </div>
            </div>
        </section>
    )
}


const MoneyBackGuaranteeBox = ({ navigateToBuyNow }) => (
    <>
        <div className="col-12 mt-3">
            <img src="assets/money-back-garuntee.webp" className="img-fluid" alt="" />
        </div>
        <div className='row d-flex align-items-center'>
            <div className="col-6 bottom-price " style={{ position: 'relative' }}>
                <h2 className="price ml-xl-2">₹ <span className='original-price'>1290</span> <span className="offer-price">990</span></h2>
            </div>
            <div className="col-5">
                <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
            </div>
        </div>
    </>

)


export default BuyNowSection
